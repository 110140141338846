import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getAccessories }) {
  const title = "Accessories";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Accessory";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [skin, setSkin] = useState([]);
  const [skinShow, setSkinShow] = useState([]);
  const [selectedSkin, setSelectedSkin] = useState([]);
  const [lesson, setLesson] = useState([]);
  const [lessonShow, setLessonShow] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState([]);
  const [activity, setActivity] = useState([]);
  const [activityShow, setActivityShow] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [bodySuits, setBodySuits] = useState([]);
  const [languageAr, setLanguagesAr] = useState({
    name: "",
    description: "",
  });
  const [languageEn, setLanguagesEn] = useState({
    name: "",
    description: "",
  });

  const [requestData, setRequestData] = useState({
    image: null,
    price: null,
    gender: null,
    body_suit_id: null,
  });
  /* activity */
  const handleActivity = (event) => {
    const {
      target: { value },
    } = event;
    setActivityShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectActivity = (event) => {
    setSelectedActivity(event.target.value);
  };
  const getActivityIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/activity",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setActivity(data.data);
  };
  let activityIds = activity.map((activityId) => ({
    id: activityId.id,
    name: activityId.name,
  }));
  useEffect(() => {
    getActivityIds();
  }, []);
  /*end of activity handling*/
  /* skin */
  const handleSkin = (event) => {
    const {
      target: { value },
    } = event;
    setSkinShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectSkin = (event) => {
    setSelectedSkin(event.target.value);
  };
  const getskinIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/skin",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSkin(data.data);
  };
  let skinIds = skin.map((skinId) => ({
    id: skinId.id,
    name: skinId.name,
  }));
  useEffect(() => {
    getskinIds();
  }, []);
  /*end of skin handling*/
  /* lesson */
  const handleLesson = (event) => {
    const {
      target: { value },
    } = event;
    setLessonShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleSelectedLesson = (event) => {
    setSelectedLesson(event.target.value);
  };
  const getLessonsIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/lesson",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setLesson(data.data);
  };
  let lessonIds = lesson.map((lessonId) => ({
    id: lessonId.id,
    name: lessonId.name,
  }));
  useEffect(() => {
    getLessonsIds();
  }, []);
  /*end of lesson handling*/
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    
    setEnErrors([]);
    setArErrors([]);
    setErrorList([]);
    setSelectedActivity([]);
    setActivityShow([]);
    setSelectedSkin([]);
    setSkinShow([]);
    setSelectedLesson([]);
    setLessonShow([]);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
    setLoading(false);

  };

  const handleImage = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };

  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"arabic description" can't be an empty field`,
        "any.required": `"arabic description" is a required field`,
      }),
   
    });
    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"english name" can't be an empty field`,
        "any.required": `"english name" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"english description" can't be an empty field`,
        "any.required": `"english description" is a required field`,
      }),

    });
    return schema.validate(en, { abortEarly: false });
  };
  const validateSubject = (requestData) => {
    let schema = Joi.object({
      image: Joi.object().required().messages({
        "object.base": `"image" can't be an empty field`,
      }),
      price: Joi.number().required().messages({
        "number.empty": `"price" can't be an empty field`,
        "any.required": `"price" is a required field`,
      }),
      gender: Joi.string().required().messages({
        "string.empty": `"gender" can't be an empty field`,
        "any.required": `"gender" is a required field`,
      }),
      body_suit_id: Joi.number().required().messages({
        "number.empty": `"body_suit_id" can't be an empty field`,
        "any.required": `"body_suit_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateSubject(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();
    formData.append("image", requestData.image);
    formData.append("price", requestData.price);
    formData.append("gender", requestData.gender);
    formData.append("body_suit_id", requestData.body_suit_id);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][description]", languageAr.description);
    formData.append("languages[0][language]", "ar");
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][description]", languageEn.description);
    formData.append("languages[1][language]","en");
    for (var i = 0; i < selectedActivity.length; i++) {
      formData.append(`activity_ids[${i}]`, selectedActivity[i]);
    }
    for (var i = 0; i < selectedLesson.length; i++) {
      formData.append(`lesson_ids[${i}]`, selectedLesson[i]);
    }
    for (var i = 0; i < selectedSkin.length; i++) {
      formData.append(`skin_ids[${i}]`, selectedSkin[i]);
    }

    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);
      setEnErrors(valEn.error.details);
    } else {
      try {
        setLoading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/accessory`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setLoading(false);
          getAccessories();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setLoading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
  //get body suits Id
  const getBodySuitsIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/body-suit",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setBodySuits(data.data);
  };
  let bodySuitsIds = bodySuits.map((bodySuit) => ({
    id: bodySuit.id,
    name: bodySuit.name,
  }));
  useEffect(() => {
    getBodySuitsIds();
  }, []);
  //end of body suits
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Accessory
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex">
            <div className="me-2 w-50">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key}: {apiErrors[key][0]}
              </div>
            ))}
          
          <div className="d-flex">
            <div className="w-50">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Price
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  id="arTitle"
                  name="price"
                  value={requestData.price}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Image
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arTitle"
                  name="image"
                  onChange={handleImage}
                />
              </div>
            </div>
            <div className="w-50 ms-3">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Body Suit 
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestData.body_suit_id}
                    onChange={handleChange}
                    name="body_suit_id"
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: false }}
                    input={<OutlinedInput label=" " />}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    className="customSelect"
                  >
                    {bodySuitsIds.map((bodySuit) => (
                      <MenuItem key={bodySuit.id} value={bodySuit.id}>
                        {bodySuit.id}{" "}
                        {`[ ${bodySuit.name ? bodySuit.name : "No Title"} ]`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <label htmlFor="arLang" className="form-label">
                Gender
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="gender"
                  value={requestData.gender}
                  onChange={handleChange}
                >
                  <option defaultValue>Choose...</option>
                  <option value="girl">Girl</option>
                  <option value="boy">Boy</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div>
            </div>
          </div>

          <div className="d-flex ">
            {/* -----------------------------------------arabic-------------------------------------------- */}

            <div className="w-50 me-3 ">
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Description
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="description"
                  value={languageAr.description}
                  onChange={handleChangeAr}
                />
              </div>
            </div>

            {/* -----------------------------------------english-------------------------------------------- */}
            <div className="w-50">
              <p className="mb-2  mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>

            
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Description
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="description"
                  value={languageEn.description}
                  onChange={handleChangeEn}
                />
              </div>
            </div>
          </div>

          <div className="w-100 d-flex mb-2">
            <FormControl sx={{ width: "50%", marginRight: 2 }}>
              <label htmlFor="arLang" className="form-label">
                Skins 
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedSkin}
                onChange={(e) => {
                  handleSkin(e);
                  handleChangeSelectSkin(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {skinIds.map((skin) => (
                  <MenuItem key={skin.id} value={skin.id}>
                    <Checkbox
                      checked={skinShow.indexOf(skin.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${skin.id} [ ${skin.name} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "50%" }}>
              <label htmlFor="arLang" className="form-label">
                Lessons
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedLesson}
                onChange={(e) => {
                  handleLesson(e);
                  handleSelectedLesson(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {lessonIds.map((lesson) => (
                  <MenuItem key={lesson.id} value={lesson.id}>
                    <Checkbox
                      checked={lessonShow.indexOf(lesson.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${lesson.id} [ ${lesson.name} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-100">
            <FormControl sx={{ width: "100%" }}>
              <label htmlFor="arLang" className="form-label">
                Activities
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedActivity}
                onChange={(e) => {
                  handleActivity(e);
                  handleChangeSelectActivity(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {activityIds.map((activity) => (
                  <MenuItem key={activity.id} value={activity.id}>
                    <Checkbox
                      checked={activityShow.indexOf(activity.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText
                      primary={`${activity.id} [ ${activity.name} ]`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getSubject }) {
  const title = "Subjects";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Subject";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [skill, setSkill] = useState([]);
  const [skillShow, setSkillShow] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [isLoading, setloading] = useState(false);
  const [ageGroup,setAgeGroups]=useState([]);
  const [quizs,setQuiz]=useState([]);
  const [certificates,setCertificate]=useState([]);

  const [languageAr, setLanguagesAr] = useState({
    name: null,
    sound_id: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    name: null,
    sound_id: null,
  });
  const [notificationsAr, setNotificationsAr] = useState({
    title: null,
    subject: null,
  });
  const [notificationsEn, setNotificationsEn] = useState({
    title: null,
    subject: null,
  });
  const [requestData, setRequestData] = useState({
    image: null,
    age_group_id: null,
    points: 0,
    quiz_id: null,
    certificate_id: null,
    notificate: 0,
  });
  /* skill */
  const handleSkill = (event) => {
    const {
      target: { value },
    } = event;
    setSkillShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectSkill = (event) => {
    setSelectedSkill(event.target.value);
  };
  const getSkillIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/skill",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSkill(data.data);
  };
  let skillIds = skill.map((skillId) =>({
    id:skillId.id,
    name:skillId.name
  }) );
  useEffect(() => {
    getSkillIds();
  }, []);
  /*end of skill handling*/
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRequestData({});
    setNotificationsAr({});
    setNotificationsEn({});
    setLanguagesAr({});
    setLanguagesEn({});
    setloading(false);
  };
  const handleImage = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeNotifAr = (event) => {
    setNotificationsAr({
      ...notificationsAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeNotifEn = (event) => {
    setNotificationsEn({
      ...notificationsEn,
      [event.target.name]: event.target.value,
    });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
  
      sound_id: Joi.optional().allow(null),
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
      sound_id: Joi.optional().allow(null),
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateSubject = (requestData) => {
    let schema = Joi.object({
      image: Joi.object().required().messages({
        "object.base": `"image" can't be an empty field`,
      }),
      age_group_id: Joi.number().required().messages({
        "string.empty": `"age_group_id" can't be an empty field`,
        "any.required": `"age_group_id" is a required field`,
      }),
      points:  Joi.optional().allow(null),
      quiz_id: Joi.number().required().messages({
        "number.empty": `"quiz_id" can't be an empty field`,
        "any.required": `"quiz_id" is a required field`,
      }),
      certificate_id: Joi.number().required().messages({
        "number.empty": `"certificate_id" can't be an empty field`,
        "any.required": `"certificate_id" is a required field`,
      }),
      notificate: Joi.optional().allow(null)
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateSubject(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();
    formData.append("image", requestData.image);
    formData.append("age_group_id", requestData.age_group_id);
    requestData.points&&
    formData.append("points", requestData.points);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][language]", "ar");
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][language]", "en");
    formData.append("quiz_id", requestData.quiz_id);
    formData.append("certificate_id", requestData.certificate_id);
    requestData.notificate&&
    formData.append("notificate", requestData.notificate);
    notificationsAr.title &&
      formData.append("notification[0][title]", notificationsAr.title);
    notificationsEn.title &&
      formData.append("notification[1][title]", notificationsEn.title);
    notificationsAr.subject &&
      formData.append("notification[0][subject]", notificationsAr.subject);
    notificationsEn.subject &&
      formData.append("notification[1][subject]", notificationsEn.subject);
      formData.append("notification[0][lang]", 'ar');
      formData.append("notification[1][lang]", 'en');
    languageAr.sound_id &&
      formData.append("languages[0][sound_id]", languageAr.sound_id);
    languageEn.sound_id &&
      formData.append("languages[1][sound_id]", languageEn.sound_id);
    for (var i = 0; i < selectedSkill.length; i++) {
      formData.append(`skill_ids[${i}]`, selectedSkill[i]);
    }
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error&&valAr.error.details);
      setEnErrors(valEn.error&&valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/subject`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getSubject();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
//get certifcate Id
const getCertificateIds = async () => {
  let { data } = await axios.get(
    "https://atfalunaapp.com/api/dashboard/certificate?type=empty",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setCertificate(data.data);
};
let certficatesIds = certificates.map((certificate) => ({
  id: certificate.id,
  title_two: certificate.title_two,
}));
useEffect(() => {
  getCertificateIds();
}, []);
//end of certifcate
//get quiz Id
const getQuizsIds = async () => {
  let { data } = await axios.get(
    "https://atfalunaapp.com/api/dashboard/quiz",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setQuiz(data.data);
};
let quizsIds = quizs.map((quiz) => ({
  id: quiz.id,
  name: quiz.name,
}));
useEffect(() => {
  getQuizsIds();
}, []);
//end of quiz
//get ageGroup Id
const getAgeGroups = async () => {
  let { data } = await axios.get(
    "https://atfalunaapp.com/api/dashboard/age-group",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setAgeGroups(data.data);
};
let ageGroupIds = ageGroup.map((ageGroup) => ({
  id: ageGroup.id,
  name: ageGroup.name,
}));
useEffect(() => {
  getAgeGroups();
}, []);
//end of ageGroup
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Subject
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex">
            <div className="me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div>
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex">
            <div className="w-50">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Image
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arTitle"
                  name="image"
                  onChange={handleImage}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Points
                  <span className="required">  </span>

                </label>
                <input
                  className="form-control"
                  type="number"
                  id="arTitle"
                  name="points"
                  value={requestData.points}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-50 ms-3">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Age Group 
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                {/* <input
                  className="form-control"
                  type="number"
                  id="arTitle"
                  name="age_group_id"
                  value={requestData.age_group_id}
                  onChange={handleChange}
                /> */}
                   <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.age_group_id}
                  onChange={handleChange}
                  name="age_group_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
                  {ageGroupIds.map((ageGroupId) => (
                    <MenuItem key={ageGroupId.id} value={ageGroupId.id}>
                      {ageGroupId.id} {`[ ${ageGroupId.name} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </div>
              <label htmlFor="arLang" className="form-label">
                Notifcate
                <span className="required">  </span>
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="notificate"
                  value={requestData.notificate}
                  onChange={handleChange}
                >
                  <option defaultValue>Choose...</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="mb-2 me-3 w-50">
              <label htmlFor="arTitle" className="form-label">
                Certificate 
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              {/* <input
                className="form-control"
                type="number"
                id="arTitle"
                name="certificate_id"
                value={requestData.certificate_id}
                onChange={handleChange}
              /> */}
               <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.certificate_id}
                  onChange={handleChange}
                  name="certificate_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
                  {certficatesIds.map((certId) => (
                    <MenuItem key={certId.id} value={certId.id}>
                      {certId.id} {`[ ${certId.title_two?certId.title_two:"No Title"} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className=" w-50">
              <label htmlFor="arTitle" className="form-label">
                Quiz 
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>                
              </label>
              {/* <input
                className="form-control"
                type="number"
                id="arTitle"
                name="quiz_id"
                value={requestData.quiz_id}
                onChange={handleChange}
              /> */}
               <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.quiz_id}
                  onChange={handleChange}
                  name="quiz_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
                  {quizsIds.map((quizId) => (
                    <MenuItem key={quizId.id} value={quizId.id}>
                      {quizId.id} {`[ ${quizId.name?quizId.name:"No Title"} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex ">
            {/* -----------------------------------------arabic-------------------------------------------- */}

            <div className="w-50 me-3 ">
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

              {/* <label htmlFor="arLang" className="form-label">
                Language
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="language"
                  value={languageAr.language}
                  onChange={handleChangeAr}
                >
                  <option defaultValue>ar</option>
                  <option value="ar">ar</option>
                  <option value="en">en</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div> */}

              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Sound Id
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="sound_id"
                  value={languageAr.sound_id}
                  onChange={handleChangeAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="title"
                  value={notificationsAr.title}
                  onChange={handleChangeNotifAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Subject
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="subject"
                  value={notificationsAr.subject}
                  onChange={handleChangeNotifAr}
                />
              </div>

              {/* <label htmlFor="arLang" className="form-label">
                Notification Language
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="lang"
                  value={notificationsAr.language}
                  onChange={handleChangeNotifAr}
                >
                  <option defaultValue>ar</option>
                  <option value="ar">ar</option>
                  <option value="en">en</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div> */}
            </div>

            {/* -----------------------------------------english-------------------------------------------- */}
            <div className="w-50">
              <p className="mb-2  mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>

              {/* <label htmlFor="arLang" className="form-label">
                Language
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="language"
                  value={languageEn.language}
                  onChange={handleChangeEn}
                >
                  <option defaultValue>en</option>
                  <option value="ar">ar</option>
                  <option value="en">en</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div> */}

              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Sound Id
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="sound_id"
                  value={languageEn.sound_id}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="title"
                  value={notificationsEn.title}
                  onChange={handleChangeNotifEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Subject
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="subject"
                  value={notificationsEn.subject}
                  onChange={handleChangeNotifEn}
                />
              </div>
              {/* <label htmlFor="arLang" className="form-label">
                Notification Language
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="lang"
                  value={notificationsEn.lang}
                  onChange={handleChangeNotifEn}
                >
                  <option defaultValue>ar</option>
                  <option value="ar">ar</option>
                  <option value="en">en</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div> */}
            </div>
          </div>

          <div className="w-100">
            <FormControl sx={{ width: "100%" }}>
              <label htmlFor="arLang" className="form-label">
                Skills
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedSkill}
                onChange={(e) => {
                  handleSkill(e);
                  handleChangeSelectSkill(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {skillIds.map((skill) => (
                  <MenuItem key={skill.id} value={skill.id}>
                    <Checkbox
                      checked={skillShow.indexOf(skill.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${skill.id} [ ${skill.name} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

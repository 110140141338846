import React, { useState, useEffect } from "react";
import img from "../../images/Group.png";
import axios from "axios";
import Joi from "joi";
import "./Login.css";
import { useAuth } from "../../providers/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [id, setLoggedId] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setloading] = useState(false);

  const { login: log } = useAuth();
  useEffect(() => {
    setErrorList([]);
  }, [user]);
  const getUser = (e) => {
    let currentUser = { ...user };
    currentUser[e.target.name] = e.target.value;
    setUser(currentUser);
  };

  const login = async (e) => {
    e.preventDefault();

    let valRes = validateLogin(user);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          "https://atfalunaapp.com/api/dashboard/auth/login",
          user
        );
        let { accessToken } = data.data.Token;
        let id = data.data.user.id;
        if (data.message === "Successful") {
          log(accessToken, id);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        setEmailError(error.response.data.errors.email);
        setPasswordError(error.response.data.errors.password);
      }
    }
  };

  const validateLogin = (user) => {
    let schema = Joi.object({
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
        .messages({
          "string.base": `"email" should be valid`,
          "string.empty": `"email" can't be an empty field`,
          "any.required": `"email" is a required field`,
        }),
      password: Joi.string().min(6).max(15).messages({
        "string.base": `"password" should be valid`,
        "string.empty": `"password" can't be an empty field`,
        "any.required": `"password" is a required field`,
      }),
    });
    return schema.validate(user, { abortEarly: false });
  };

  return (
    <div className="back">
      <div className="div-center">
        <div className="content">
          <div className="d-flex justify-content-center mb-5">
            <img src={img} alt="" className="" />
          </div>
          {/* {apiErrors.map((index)=>{
              return (
                <div key={index} className="alert alert-danger">
                  Unauthorized
                </div>
              );
          })} */}
          {emailError ? (
            <div className="alert alert-danger">{emailError} </div>
          ) : (
            ""
          )}
          {passwordError ? (
            <div className="alert alert-danger">Password is not correct </div>
          ) : (
            ""
          )}

          {errorList.map((error, index) => {
            return (
              <div key={index} className="alert alert-danger">
                {error.message}
              </div>
            );
          })}

          <form onSubmit={login}>
            <div className="form-group py-2 formStyle">
              <div className="input-field">
                {" "}
                <span className="far fa-user p-2"></span>{" "}
                <input
                  type="text"
                  onChange={getUser}
                  placeholder="Email"
                  name="email"
                  required
                  className=""
                  autoComplete="on"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      login(e);
                    }
                  }}
                />{" "}
              </div>
            </div>
            <div className="form-group py-2 formStyle">
              <div className="input-field">
                {" "}
                <span className="fas fa-lock p-2"></span>{" "}
                <input
                  type="password"
                  onChange={getUser}
                  placeholder="Password"
                  name="password"
                  required
                  className=""
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      login(e);
                    }
                  }}
                />{" "}
              </div>
            </div>
          </form>
          {/* <button
            onClick={login}
            className="btn btn-dark w-100  loginBtn mt-2 p-2"
          >
            
            {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "LOGIN"
              )}{" "}
          </button> */}
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <button className="btn btn-dark w-25 smallLogin loginBtn mt-2 p-2">
                <div className="d-flex justify-content-center">
                  <CircularProgress className="loading" />
                </div>
              </button>
            </div>
          ) : (
            <button
              onClick={login}
              className="btn btn-dark w-100  loginBtn mt-2 p-2"
            >
              LOGIN
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;

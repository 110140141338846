import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import Joi from "joi";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ getAvatars }) {
  const title = "Avatars";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Avatar";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [isLoading, setloading] = useState(false);

  const [apiErrors, setApiErrors] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({});
  };

  const [requestData, setRequestData] = useState({
    type: "",
    price: null,
    name: null,
  });
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeType = (event) => {
    setValue(event.target.value);
    if (event.target.checked) {
      setRequestData({
        ...requestData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const validateAvatar = (requestData) => {
    let schema = Joi.object({
      type: Joi.string().required().messages({
        "string.empty": `"type" can't be an empty field`,
        "any.required": `"type" is a required field`,
      }),
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      price: Joi.number().required().messages({
        "number.empty": `"price" can't be an empty field`,
        "any.required": `"price" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateAvatar(requestData);

    const formData = new FormData();
    formData.append("type", requestData.type);
    formData.append("price", requestData.price);
    formData.append("name", requestData.name);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);

        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/avatar`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getAvatars();
          setRequestData({});
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);

  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Avatar
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key}: {apiErrors[key][0]}
              </div>
            ))}
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={value}>
              <div className="m-auto ">
                <FormControlLabel
                  value="boy"
                  control={<Radio />}
                  label="Boy"
                  name="type"
                  onChange={handleChangeType}
                />
                <FormControlLabel
                  value="girl"
                  name="type"
                  control={<Radio />}
                  label="Girl"
                  onChange={handleChangeType}
                />
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </div>
            </RadioGroup>
          </FormControl>

          <div className="mb-2">
            <label htmlFor="price" className="form-label">
              Price
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="number"
              id="price"
              name="price"
              value={requestData.price}
              onChange={handleChange}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="formFile" className="form-label">
              Name
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="formFile"
              name="name"
              value={requestData.name}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

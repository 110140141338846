import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import axios from "axios";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import LinearProgress from "@mui/material/LinearProgress";
import BasicHeader  from "../BasicHeader/BasicHeader";
export default function Achievements() {
  let authToken = localStorage.getItem("userToken");
  const [basics, setBasics] = useState([]);
  const title = "Basics";
  const [basic, setBasic] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [id, setId] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };

  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/basic/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setBasic(data.data[0]);
    console.log(data.data[0])
  };
  const getBasics = async () => {
    let { data } = await axios
      .get("https://atfalunaapp.com/api/dashboard/basic/collection", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setBasics(data.data);
  };

  useEffect(() => {
    getBasics();
  }, []);

  return (
    <div className="">
      <BasicHeader title={title}/>
      <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        basic={basic}
        setBasic={setBasic}
        id={id}
        getBasics={getBasics}
      />
    
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: "auto" }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Item
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Information
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {basics
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.item}</TableCell>

                      <TableCell align="center">{row.info}</TableCell>

                      <TableCell align="center">
                     
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={basics.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

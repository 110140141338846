import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Joi from "joi";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  basic,
  setBasic,
  id,
  getBasics,
}) {
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [requestData, setRequestData] = useState({
    item: "",
    info: "",
  });
  useEffect(() => {
  let{item,info}=basic;
  setRequestData({item,info});
  }, [basic]);
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setUpdatePopUp(false);
  };


  let authToken = localStorage.getItem("userToken");

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("item", requestData.item);
    formData.append("info", requestData.info);


    try {
      setloading(true);

      let { data } = await axios.post(
        `https://atfalunaapp.com/api/dashboard/basic/${id}/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      );

      if (data.message === "Successful") {
        setUpdatePopUp(false);
        setloading(false);
        getBasics();
        setRequestData("");
      }
    } catch (error) {
      getBasics();
      setApiErrors(error.response.data.errors);
      setloading(false);
      console.log(error.response.data.errors);
    }
  };

  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} : {apiErrors[key][0]}
              </div>
            ))}
          <div className="w-100">
            <label htmlFor="arLang" className="form-label">
              Item
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control mb-3"
              type="text"
              id="arTitle"
              name="item"
              placeholder={basic.item}
              value={requestData.item}
              onChange={handleChange}
            />
          </div>
          <div className="w-100">
            <label htmlFor="arLang" className="form-label">
              Info <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control mb-3"
              type="text"
              id="arTitle"
              name="info"
              placeholder={basic.info}
              value={requestData.info}
              onChange={handleChange}
            />
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getGames }) {
  const title = "Games";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Game";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [skill, setSkill] = useState([]);
  const [skillShow, setSkillShow] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [quizShow, setQuizShow] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState([]);
  const [isLoading, setloading] = useState(false);
  const [lessonTypes, setLessonTypes] = useState([]);
  const [subSubjects, setSubSubjects] = useState([]);
  const [languageAr, setLanguagesAr] = useState({
    name: null,
    image_one: null,
    image_two: null,
    url: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    name: null,
    image_one: null,
    image_two: null,
    url: null,
  });
  const [notificationsAr, setNotificationsAr] = useState({
    title: null,
    subject: null,
  });
  const [notificationsEn, setNotificationsEn] = useState({
    title: null,
    subject: null,
  });
  const [requestData, setRequestData] = useState({
    sub_subject_id: null,
    points: 0,
    lesson_type_id: null,
    notificate: 0,
  });
  /* skill */
  const handleSkill = (event) => {
    const {
      target: { value },
    } = event;
    setSkillShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectSkill = (event) => {
    setSelectedSkill(event.target.value);
  };
  const getSkillIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/skill",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSkill(data.data);
  };
  let skillIds = skill.map((skillId) => ({
    id: skillId.id,
    name: skillId.name,
  }));
  useEffect(() => {
    getSkillIds();
  }, []);
  /*end of skill handling*/
  /* quiz */
  const handleQuiz = (event) => {
    const {
      target: { value },
    } = event;
    setQuizShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleSelectedQuiz = (event) => {
    setSelectedQuiz(event.target.value);
  };
  const getQuizIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/quiz",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuiz(data.data);
  };
  let quizIds = quiz.map((quizId) => ({
    id: quizId.id,
    name: quizId.name,
  }));
  useEffect(() => {
    getQuizIds();
  }, []);
  /*end of quiz handling*/
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRequestData({});
    setNotificationsAr({});
    setNotificationsEn({});
    setLanguagesAr({});
    setLanguagesEn({});
    setloading(false);
  };
  const handleImageOneAr = (e) => {
    setLanguagesAr({ ...languageAr, image_one: e.target.files[0] });
  };
  const handleImageTwoAr = (e) => {
    setLanguagesAr({ ...languageAr, image_two: e.target.files[0] });
  };
  const handleImageOneEn = (e) => {
    setLanguagesEn({ ...languageEn, image_one: e.target.files[0] });
  };
  const handleImageTwoEn = (e) => {
    setLanguagesEn({ ...languageEn, image_two: e.target.files[0] });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeNotifAr = (event) => {
    setNotificationsAr({
      ...notificationsAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeNotifEn = (event) => {
    setNotificationsEn({
      ...notificationsEn,
      [event.target.name]: event.target.value,
    });
  };
  const handleFilesAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      [e.target.name]: e.target.files[0],
    });
  };
  const handleFilesEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      [e.target.name]: e.target.files[0],
    });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
  
      image_one: Joi.object().required().messages({
        "object.base": `"image_one" can't be an empty field`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"image_two" can't be an empty field`,
      }),
      url: Joi.object().required().messages({
        "object.base": `"url" can't be empty`,
      }),
    });
    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"english name" can't be an empty field`,
        "any.required": `"english name" is a required field`,
      }),
 
      image_one: Joi.object().required().messages({
        "object.base": `"image_one" can't be an empty field`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"image_two" can't be an empty field`,
      }),
      url: Joi.object().required().messages({
        "object.base": `"url" can't be empty`,
      }),
    });
    return schema.validate(en, { abortEarly: false });
  };
  const validateSubject = (requestData) => {
    let schema = Joi.object({
      sub_subject_id: Joi.number().required().messages({
        "number.empty": `"sub_subject_id" can't be an empty field`,
        "any.required": `"sub_subject_id" is a required field`,
      }),
      points: Joi.optional().allow(null),
      lesson_type_id: Joi.number().required().messages({
        "number.empty": `"lesson_type_id" can't be an empty field`,
        "any.required": `"lesson_type_id" is a required field`,
      }),
      notificate: Joi.optional().allow(null),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateSubject(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();
    requestData.points && formData.append("points", requestData.points);
    formData.append("sub_subject_id", requestData.sub_subject_id);
    formData.append("lesson_type_id", requestData.lesson_type_id);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][image_one]", languageAr.image_one);
    formData.append("languages[0][image_two]", languageAr.image_two);
    formData.append("languages[0][language]", 'ar');
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][image_one]", languageEn.image_one);
    formData.append("languages[1][image_two]", languageEn.image_two);
    formData.append("languages[1][language]", 'en');
    formData.append("languages[0][url]", languageEn.url);
    formData.append("languages[1][url]", languageEn.url);
    for (var i = 0; i < selectedSkill.length; i++) {
      formData.append(`skill_ids[${i}]`, selectedSkill[i]);
    }
    for (var i = 0; i < selectedQuiz.length; i++) {
      formData.append(`quiz_ids[${i}]`, selectedQuiz[i]);
    }
    requestData.notificate &&
      formData.append("notificate", requestData.notificate);
    notificationsAr.title &&
      formData.append("notification[0][title]", notificationsAr.title);
    notificationsEn.title &&
      formData.append("notification[1][title]", notificationsEn.title);
    notificationsAr.subject &&
      formData.append("notification[0][subject]", notificationsAr.subject);
    notificationsEn.subject &&
      formData.append("notification[1][subject]", notificationsEn.subject);
      formData.append("notification[0][lang]", 'ar');
      formData.append("notification[1][lang]", 'en');

    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);
      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/lesson`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getGames();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
  //get subsubject Id
  const getSubSubjectIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/sub-subject",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubSubjects(data.data);
  };
  let subSubjectsId = subSubjects.map((subSubject) => ({
    id: subSubject.id,
    name: subSubject.name,
  }));
  useEffect(() => {
    getSubSubjectIds();
  }, []);
  //end of subsubject
  //get lessontype Id
  const getLessonTypes = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/lesson-type",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setLessonTypes(data.data);
  };
  let lessonTypeIds = lessonTypes.map((lessonType) => ({
    id: lessonType.id,
    name: lessonType.name,
  }));
  useEffect(() => {
    getLessonTypes();
  }, []);
  //end of lessontype
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Game
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex">
            <div className="me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div>
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex">
            <div className="w-50">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Sub Subject 
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestData.sub_subject_id}
                    onChange={handleChange}
                    name="sub_subject_id"
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: false }}
                    input={<OutlinedInput label=" " />}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    className="customSelect"
                  >
                    {subSubjectsId.map((subSubject) => (
                      <MenuItem key={subSubject.id} value={subSubject.id}>
                        {subSubject.id}{" "}
                        {`[ ${
                          subSubject.name ? subSubject.name : "No Title"
                        } ]`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Points
                  <span className="required"> </span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  id="arTitle"
                  name="points"
                  value={requestData.points}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-50 ms-3">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Lesson Type 
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestData.lesson_type_id}
                    onChange={handleChange}
                    name="lesson_type_id"
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: false }}
                    input={<OutlinedInput label=" " />}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    className="customSelect"
                  >
                    {lessonTypeIds.map((lessonType) => (
                      <MenuItem key={lessonType.id} value={lessonType.id}>
                        {lessonType.id}{" "}
                        {`[ ${
                          lessonType.name ? lessonType.name : "No Title"
                        } ]`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <label htmlFor="arLang" className="form-label">
                Notifcate
                <span className="required"> </span>
              </label>

              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="inputGroupSelect02"
                  name="notificate"
                  value={requestData.notificate}
                  onChange={handleChange}
                >
                  <option defaultValue>Choose...</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div>
            </div>
          </div>

          <div className="d-flex ">
            {/* -----------------------------------------arabic-------------------------------------------- */}

            <div className="w-50 me-3 ">
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

             
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Image One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arTitle"
                  name="image_one"
                  onChange={handleImageOneAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Image Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arTitle"
                  name="image_two"
                  onChange={handleImageTwoAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Url
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arVideo"
                  name="url"
                  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  onChange={handleFilesAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="title"
                  value={notificationsAr.title}
                  onChange={handleChangeNotifAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Subject
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="subject"
                  value={notificationsAr.subject}
                  onChange={handleChangeNotifAr}
                />
              </div>

              {/* <label htmlFor="arLang" className="form-label">
                Notification Language
              </label>

              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="inputGroupSelect02"
                  name="lang"
                  value={notificationsAr.language}
                  onChange={handleChangeNotifAr}
                >
                  <option defaultValue>Choose...</option>
                  <option value="ar">ar</option>
                  <option value="en">en</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div> */}
            </div>

            {/* -----------------------------------------english-------------------------------------------- */}
            <div className="w-50">
              <p className="mb-2  mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Image One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arTitle"
                  name="image_one"
                  onChange={handleImageOneEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Image Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arTitle"
                  name="image_two"
                  onChange={handleImageTwoEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Url
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arVideo"
                  name="url"
                  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  onChange={handleFilesEn}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="title"
                  value={notificationsEn.title}
                  onChange={handleChangeNotifEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Notification Subject
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arVideo"
                  name="subject"
                  value={notificationsEn.subject}
                  onChange={handleChangeNotifEn}
                />
              </div>
              {/* <label htmlFor="arLang" className="form-label">
                Notification Language
              </label>
         
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="inputGroupSelect02"
                  name="lang"
                  value={notificationsEn.lang}
                  onChange={handleChangeNotifEn}
                >
                  <option defaultValue>Choose...</option>
                  <option value="ar">ar</option>
                  <option value="en">en</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div> */}
            </div>
          </div>

          <div className="w-100 d-flex">
            <FormControl sx={{ width: "50%", marginRight: 2 }}>
              <label htmlFor="arLang" className="form-label">
                Skills
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedSkill}
                onChange={(e) => {
                  handleSkill(e);
                  handleChangeSelectSkill(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {skillIds.map((skill) => (
                  <MenuItem key={skill.id} value={skill.id}>
                    <Checkbox
                      checked={skillShow.indexOf(skill.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${skill.id} [ ${skill.name} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <label htmlFor="arLang" className="form-label">
                Quizs
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedQuiz}
                onChange={(e) => {
                  handleQuiz(e);
                  handleSelectedQuiz(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {quizIds.map((quiz) => (
                  <MenuItem key={quiz.id} value={quiz.id}>
                    <Checkbox
                      checked={quizShow.indexOf(quiz.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${quiz.id} [ ${quiz.name} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialRequestId = {
  age: null,
  certificate_id: null,
};
export default function UpdatePopUp({
  updatePopUp,
  setUpdatePopUp,
  ageGroup,
  id,
  getAgeGroups,
  ar,
  en,
}) {
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const handleClose = () => {
    setUpdatePopUp(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({...initialRequestId});
    setLanguagesAr({});
    setLanguagesEn({});
  };
  const [requestData, setRequestData] = useState({
    ...initialRequestId,
  });
  const [languageAr, setLanguagesAr] = useState({
    name: "",
  });
  const [languageEn, setLanguagesEn] = useState({
    name: "",
  });
  useEffect(() => {
    let { name } = ar;
    setLanguagesAr({ name });
  }, [ar]);
  useEffect(() => {
    let { name } = en;
    setLanguagesEn({ name });
  }, [en]);
  useEffect(() => {
    if (ageGroup) {
      let {  certificate } = ageGroup;
      setRequestData({ certificate_id: certificate?.id || 0 });
    }
  }, [ageGroup]);

  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"english name" can't be an empty field`,
        "any.required": `"english name" is a required field`,
      }),
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateAgeGroup = (requestData) => {
    let schema = Joi.object({
      age: Joi.number().min(1).required().messages({
        "number.empty": `"age" can't be an empty field`,
        "any.required": `"age" is a required field`,
      }),
      certificate_id: Joi.number().required().messages({
        "number.empty": `"certificate_id" can't be an empty field`,
        "any.required": `"certificate_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  let authToken = localStorage.getItem("userToken");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let valRes = validateAgeGroup(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    formData.append("age", requestData.age);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][language]", "ar");
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][language]", "en");
    formData.append("certificate_id", requestData.certificate_id);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);

      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/age-group/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getAgeGroups();
          setRequestData("");
        }
      } catch (error) {
        getAgeGroups();
        setApiErrors(error.response.data.errors);
        setloading(false);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  //get certifcate Id
  const getCertificateIds = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/certificate",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCertificates(data.data);
  };
  let certficatesIds = certificates.map((certificate) => ({
    id: certificate.id,
    title_two: certificate.title_two,
  }));
  useEffect(() => {
    getCertificateIds();
  }, []);
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex">
            <div className="me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div>
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="d-flex w-100">
            <div className="w-50">
              {/* --------------------------------------------Arabic-------------------------------------------- */}
              <p className="mb-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

              {/*------------------------------------------End of Arabic---------------------------------------  */}
            </div>
            <div className="ms-3 w-50">
              {/*------------------------------------------English---------------------------------------------  */}

              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Name <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>
            </div>
          </div>
          {/*------------------------------------------end of English---------------------------------------------  */}

          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Age <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="degree"
              name="age"
              value={requestData.age}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Certificate <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={requestData.certificate_id}
                onChange={handleChange}
                name="certificate_id"
                MenuProps={MenuProps}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                className="customSelect"
              >
                {certficatesIds.map((certId) => (
                  <MenuItem key={certId.id} value={certId.id}>
                    {certId.id}{" "}
                    {`[ ${certId.title_two ? certId.title_two : "No Title"} ]`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BasicModal({ getUsers }) {
  const title = "Users";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New User";

  const [open, setOpen] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [value, setValue] = React.useState("");
  const [countries,setCountries]=useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({});
    setValue("");
  };
  const togglePasswordConfirm = () => {
    if (passwordConfirmType === "password") {
      setPasswordConfirmType("text");
      return;
    }
    setPasswordConfirmType("password");
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [requestData, setRequestData] = useState({
    name: "",
    email: "",
    country_id: "",
    phone: "",
    password: "",
    password_confirmation: "",
    birthdate: "",
    role_id:null,
    avatar: null,
    active:0,
  });
  const handleImage = (e) => {
    setRequestData({ ...requestData, avatar: e.target.files[0] });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
const   handleChangeRadio =(event)=>{
  setValue(event.target.value);
  if (event.target.checked) {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  }
}
//get countries Id
const getCountries = async () => {
  let { data } = await axios.get(
    "https://atfalunaapp.com/api/dashboard/country",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setCountries(data.data);
};
let countriesIds = countries.map((country) => ({
  id: country.id,
  name: country.name,
}));
useEffect(() => {
  getCountries();
}, []);
//end of countries Id

  const validateRegister = (user) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: false}})
        .messages({
          "string.base": `"email" should be valid`,
          "string.empty": `"email" can't be an empty field`,
          "any.required": `"email" is a required field`,
        }),
      country_id: Joi.number().required().messages({
        "number.empty": `"country_id" can't be an empty field`,
        "any.required": `"country_id" is a required field`,
      }),
      phone: Joi.string()
        .min(10)
        .max(15)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "number.empty": `"number" can't be an empty field`,
          "any.required": `"number" is a required field`,
        }),
      password: Joi.string()
        .min(6)
        .max(15)
        .regex(new RegExp("^.{6,15}$"))
        .messages({
          "string.base": `"password" should be valid`,
          "string.empty": `"password" can't be an empty field`,
          "any.required": `"password" is a required field`,
          "string.pattern.base": `"password" pattern doesn't match this password`,
        }),
      password_confirmation: Joi.string()
        .min(6)
        .max(15)
        .regex(new RegExp("^.{6,15}$"))
        .messages({
          "string.base": `"password confirmation" should be valid`,
          "string.empty": `"password confirmation" can't be an empty field`,
          "any.required": `"password confirmation" is a required field`,
          "string.pattern.base": `"password confirmation" pattern doesn't match this password`,
        }),
      birthdate: Joi.string().required().messages({
        "string.base": `"birthdate" should be valid`,
        "string.empty": `"birthdate" can't be an empty field`,
      }),
      role_id: Joi.number().required().messages({
        "number.empty": `"role_id" can't be an empty field`,
        "any.required": `"role_id" is a required field`,
      }),
      avatar: Joi.optional().allow(null),
      active: Joi.optional().allow(null),
    });
    return schema.validate(user, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateRegister(requestData);
    const formData = new FormData();
    formData.append("name", requestData.name);
    formData.append("email", requestData.email);
    formData.append("country_id", requestData.country_id);
    formData.append("phone", requestData.phone);
    formData.append("password", requestData.password);
    formData.append("password_confirmation", requestData.password_confirmation);
    formData.append("birthdate", requestData.birthdate);
    formData.append("role_id", requestData.role_id);
    formData.append("active", requestData.active);
    requestData.avatar && formData.append("avatar", requestData.avatar);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/user/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );
        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getUsers();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);

  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New User
          </Typography>

          <div>
            {errorList &&
              errorList.map((error, index) => {
                return (
                  <div key={index} className="alert alert-danger">
                    {error.message}
                  </div>
                );
              })}
            {apiErrors &&
              Object.keys(apiErrors).map((key) => (
                <div key={key} className="alert alert-danger">
                  {key} must be {apiErrors[key][0].split(".")[1]}
                </div>
              ))}
            <div className="mb-2">
              <label htmlFor="arTitle" className="form-label">
                Name
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="text"
                id="arTitle"
                name="name"
                value={requestData.name}
                onChange={handleChange}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="arHeader" className="form-label">
                Email
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="text"
                id="arHeader"
                name="email"
                value={requestData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="arAudio" className="form-label">
                Phone
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="text"
                id="arAudio"
                name="phone"
                value={requestData.phone}
                onChange={handleChange}
              />
            </div>
            {/* <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  id="arVideo"
                  name="password"
                  value={requestData.password}
                  onChange={handleChange}
                />
              </div> */}
            <div className="mb-2">
              <label htmlFor="password" className="form-label">
                Password
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <div className="position-relative">
              <input
                className="form-control formReg"
                type={passwordType}
                id="password"
                name="password"
                value={requestData.password}
                onChange={handleChange}
              />
              {passwordType == "password" ? (
                <VisibilityIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePassword}
                />
              ) : (
                <VisibilityOffIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePassword}
                />
              )}
            </div>
            </div>
            <div className="mb-2 ">
              <label htmlFor="password_confirmation" className="form-label">
                Password Confirmation <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <div className="position-relative">
              <input
                className="form-control formReg bg-none"
                type={passwordConfirmType}
                id="password_confirmation"
                name="password_confirmation"
                value={requestData.password_confirmation}
                onChange={handleChange}
              />
              {passwordConfirmType === "password" ? (
                <VisibilityIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePasswordConfirm}
                />
              ) : (
                <VisibilityOffIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePasswordConfirm}
                />
              )}
            </div>
            </div>
            {/* <div className="mb-2 ">
              <label htmlFor="country_id" className="form-label">
                Country Id <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control formReg"
                type="text"
                id="country_id"
                name="country_id"
                value={requestData.country_id}
                onChange={handleChange}
              />
            </div> */}
            <div className="mb-2">
              <label htmlFor="arVideo" className="form-label">
              Country Id 
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
          
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.country_id}
                  onChange={handleChange}
                  name="country_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
             {countriesIds.map((countryId) => (
                    <MenuItem key={countryId.id} value={countryId.id}>
                      {countryId.id} {`[ ${countryId.name} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          
          </div>
            <div className="mb-2">
              <label htmlFor="birthdate" className="form-label">
                Birthdate
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>

              <input
                type="date"
                id="birthdate"
                name="birthdate"
                className="form-control formReg bg-none"
                value={requestData.birthdate}
                onChange={handleChange}
              ></input>
            </div>
            
            <div className="mb-2">
              <label htmlFor="avatar" className="form-label">
                Avatar
              </label>

              <input
                type="file"
                id="avatar"
                className="form-control"
                name="avatar"
                onChange={handleImage}
              ></input>
            </div>
          </div>
          <label htmlFor="avatar" className="form-label">
                Active
              </label>
          <div className="input-group mb-2">
       
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="active"
              value={requestData.active}
              onChange={handleChange}
            >
              <option defaultValue>Choose...</option>
              <option value="0">0</option>
              <option value="1">1</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>
          <FormControl component="fieldset" className="mt-2 ms-1">
            
            <RadioGroup aria-label="gender" name="gender1" value={value} >
             <div className="m-auto ">
        <FormControlLabel
                value="1"
                control={<Radio />}
                label="Admin"
                name="role_id"
                onChange={handleChangeRadio}
              />
              <FormControlLabel
                value="2"
                name="role_id"
                control={<Radio />}
                label="Parent"
                onChange={handleChangeRadio}
              />
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
             </div>
            </RadioGroup>
          </FormControl>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import axios from "axios";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import LinearProgress from "@mui/material/LinearProgress";
import AddAvatarModal from "../AddAvatarModal/AddAvatarModal";
import ViewModal from "../ViewModal/ViewModal";
import SkinDetails from "../SkinDetails/SkinDetails";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CachedIcon from "@mui/icons-material/Cached";
import FilterListIcon from "@mui/icons-material/FilterList";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function Mcq() {
  let authToken = localStorage.getItem("userToken");
  const [avatars, setAvatars] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [avatarId, setAvatarId] = useState();
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [skinDetails, setSkinDetails] = useState(false);
  const [skin, setSkin] = useState([]);
  const [skins, setSkins] = useState([]);
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const [filter, setFilter] = useState(false);
  const [subUsers, setSubUsers] = useState([]);
  const [filterData, setFilterData] = useState({
    sub_user_id: null,
    gender: null,
    free: null,
    bought: null,
  });
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleSkinDetails = () => {
    setSkinDetails(!skinDetails);
  };
  const handleFilter = () => {
    setFilter(!filter);
  };
  const handleChangeFilters = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const showFiltered = async () => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/avatar/?sub_user_id=${
        filterData.sub_user_id == null ? "" : filterData.sub_user_id
      }&gender=${filterData.gender == null ? "" : filterData.gender}&free=${
        filterData.free == null ? "" : filterData.free
      }&bought=${filterData.bought == null ? "" : filterData.bought}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAvatars(data.data);
  };
  //get sub user Id
  const getSubUsers = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/sub-user",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubUsers(data.data);
  };
  let subUsersIds = subUsers.map((subUser) => ({
    id: subUser.id,
    name: subUser.name,
  }));
  useEffect(() => {
    getSubUsers();
  }, []);
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/avatar/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAvatar(data.data[0]);
    setSkin(await data.data[0].skins[0]);
    setSkins(await data.data[0].skins);
  };
  const getAvatars = async () => {
    let { data } = await axios
      .get("https://atfalunaapp.com/api/dashboard/avatar", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setAvatars(data.data);
  };

  useEffect(() => {
    getAvatars();
  }, []);

  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/avatar/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAvatar(data.data[0]);
    setSkin(await data.data[0].skins[0]);
  };
  const deleteRow = (id) => {
    const temp = [...avatars];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/avatar/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAvatars(temp);
    getAvatars();
    setDeletePopUp(!deletePopUp);
  };
  const clearFilter = () => {
    setFilterData({sub_user_id: "",
      gender: "",
      free: "",
      bought: "",});
    getAvatars();
  };

  return (
    <div className="">
      <AddAvatarModal getAvatars={getAvatars} />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        avatar={avatar}
        setAvatar={setAvatar}
        skin={skin}
      />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        deleteRow={deleteRow}
        id={avatarId}
      />

      <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        avatar={avatar}
        setAvatar={setAvatar}
        id={avatarId}
        getAvatars={getAvatars}
      />
      <SkinDetails
        skinDetails={skinDetails}
        setSkinDetails={setSkinDetails}
        skins={skins}
        setSkins={setSkins}
      />
      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
     <div className="deleteForeverDiv mb-3">
        <div className="filerDiv d-flex description">
          <FilterListIcon className={"filterIcon"} onClick={handleFilter} />

          <div
            className="describeFilter"
            onClick={() => {
              handleFilter();
            }}
          >
            Filter
          </div>
        </div>
        <div className=" ms-2 me-2 divider align-self-center"></div>
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
           <div
          className="describeDel"
          onClick={() => {
            handleDeletePermenant();
          }}
        >
          Trash
        </div>
        </div>
       
      </div>
      <div className={`collapse mb-3   ${filter ? "d-flex" : "collapse"}`}>
   
     <div className="mb-2 w-25 me-2">
          <label htmlFor="arHeader" className="form-label">
            Sub User
          </label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="sub_user_id"
              value={filterData.sub_user_id}
              onChange={handleChangeFilters}
              MenuProps={MenuProps}
              InputLabelProps={{ shrink: false }}
              input={<OutlinedInput label=" " />}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              className="customSelect form-control"
            >
              {subUsersIds.map((subUser) => (
                <MenuItem key={subUser.id} value={subUser.id}>
                  {subUser.id} {`[${subUser.name ? subUser.name : "No Title"}]`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 me-2">
          <label htmlFor="avatar" className="form-label">
            Gender
          </label>
          <div className="input-group mb-2">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="gender"
              value={filterData.gender}
              onChange={handleChangeFilters}
            >
              <option defaultValue>Choose...</option>
              <option value="boy">Boy</option>
              <option value="girl">Girl</option>
            </select>
           
          </div>
        </div>
      
        <div className="w-25 me-2">
          <label htmlFor="avatar" className="form-label">
            Free
          </label>
          <div className="input-group mb-2">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="free"
              value={filterData.free}
              onChange={handleChangeFilters}
            >
              <option defaultValue>Choose...</option>
              <option value="0">0</option>
              <option value="1">1</option>
            </select>
           
          </div>
        </div>
        <div className="w-25 me-2">
          <label htmlFor="avatar" className="form-label">
        Bought
          </label>
          <div className="input-group mb-2">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="bought"
              value={filterData.bought}
              onChange={handleChangeFilters}
            >
              <option defaultValue>Choose...</option>
              <option value="0">0</option>
              <option value="1">1</option>
            </select>
           
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-4 ">
         
          <FilterListIcon className={"filterIcon"} onClick={showFiltered} />    
          <CachedIcon onClick={clearFilter} className={"reload"} />
        </div>
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Type
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Price
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Skins
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avatars
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>

                  
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleSkinDetails();
                              viewBeforeUpdate(row.id);
                            }}
                          >
                            Skins
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setAvatarId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setAvatarId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={avatars.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddNewGovernmentModal from "../AddNewGovernment/AddNewGovernmentModal";
import { useState, useEffect } from "react";
import axios from "axios";
import DeletePopUp from "../DeletePopUp/DeletePopUp";
import ViewModal from "../ViewModal/ViewModal";
import UpdateModal from "../UpdateModal/UpdateModal";
import LinearProgress from "@mui/material/LinearProgress";

export default function GovernmentTable() {
  let authToken = localStorage.getItem("userToken");
  const [governments, setGovernments] = useState([]);
  const [government, setGovernment] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [id, setId] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [country, setCountry] = useState({});
  const [loading, setloading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const getGovernments = async () => {
    let { data } = await axios
      .get("https://atfalunaapp.com/api/dashboard/government", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setGovernments(data.data);
    setCountry(data.data[0].country);

  };

  useEffect(() => {
    getGovernments();
  },[]);
  const deleteRow = (id) => {
    const temp = [...governments];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/government/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGovernments(temp);
    setDeletePopUp(!deletePopUp);
    getGovernments();
  };

  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/government/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGovernment(data.data[0]);
    console.log(data.data[0]);
    setCountry(data.data[0].country);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/government/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGovernment(data.data[0]);
    console.log(data.data[0]);

    setCountry(data.data[0].country);
  };

  return (
    <div className="">
      <AddNewGovernmentModal getGovernments={getGovernments} />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getGovernments={getGovernments}
        deleteRow={deleteRow}
        id={id}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        government={government}
        country={country}
        setGovernment={setGovernment}
      />
      <UpdateModal
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        government={government}
        setGovernment={setGovernment}
        id={id}
        country={country}
        getGovernments={getGovernments}
      />
   
       {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  Government ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Government Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Country Id
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Country Name
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Country Code
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Country Language
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {governments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" align="center" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.country.id}</TableCell>
                      <TableCell align="center">{row.country.name}</TableCell>
                      <TableCell align="center">{row.country.code}</TableCell>
                      <TableCell align="center">
                        {row.country.language}
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            handleUpdate();
                            viewBeforeUpdate(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={governments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

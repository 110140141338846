import React, { useState, useEffect } from "react";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import axios from "axios";
import avatar from "../../../images/img.png";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { Logout } from "@mui/icons-material";
import { useAuth } from "../../../providers/AuthProvider";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const Profile = () => {
  const { logout } = useAuth();
  const title = "My Profile";
  const [user, setUser] = useState({});
  let loggedId = localStorage.getItem("loggedId");
  let authToken = localStorage.getItem("userToken");
  const viewProfile = async (loggedId) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/user/${loggedId}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setUser(data.data.UserModel);
  };
  useEffect(() => {
    viewProfile(loggedId);
  }, []);
  return (
    <div>
      <ProfileHeader title={title} />

      <div className="whiteBg">
        <div>
          {user.avatar ? (
            <div className="d-flex justify-content-center ">
              <StyledBadge
                overlap="circular"
                className="profilePic mt-5"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="User" src={user.avatar} className="w-100 h-100" />
              </StyledBadge>
            </div>
          ) : (
            <div className="d-flex justify-content-center ">
              <StyledBadge
                overlap="circular"
                className="profilePic mt-5"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="User" src={avatar} className="w-100 h-100" />
              </StyledBadge>
            </div>
          )}
        </div>
        <div className=" text-center mb-4 d-flex justify-content-center mt-2 align-items-center">
          {user.name ? (
            <h5 className="nameText mt-2">{user.name}</h5>
          ) : (
            <h5 className="nameText mt-2">User Name</h5>
          )}
        </div>

        <div className="row justify-content-center ">
          <div className="mb-2 col-md-4">
            <label htmlFor="email" className="form-label">
              User Id
            </label>
            {user.id ? (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder={user.id}
                disabled
              />
            ) : (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder="User Id"
                disabled
              />
            )}
          </div>
          <div className="mb-2 col-md-4">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            {user.email ? (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder={user.email}
                disabled
              />
            ) : (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder="User Email"
                disabled
              />
            )}
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="mb-2 col-md-4">
            {" "}
            <label htmlFor="country_id" className="form-label">
              Country Id
            </label>
            {user.country_id ? (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder={user.country_id}
                disabled
              />
            ) : (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder="Country Id"
                disabled
              />
            )}
          </div>
          <div className="mb-2 col-md-4">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            {user.phone ? (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder={user.phone}
                disabled
              />
            ) : (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder="User Phone"
                disabled
              />
            )}
          </div>
        </div>
        <div className="row  justify-content-center pb-5">
          <div className="mb-2 col-md-4 ">
            <label htmlFor="birthdate" className="form-label">
              Birthdate
            </label>

            {user.birthdate ? (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder={user.birthdate}
                disabled
              />
            ) : (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder="User Birthdate"
                disabled
              />
            )}
          </div>
          <div className="mb-2 col-md-4 ">
            <label htmlFor="birthdate" className="form-label">
              User Role
            </label>

            {user.UserRoles && user.UserRoles[0].name ? (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder={user.UserRoles && user.UserRoles[0].name}
                disabled
              />
            ) : (
              <input
                className="form-control  bg-none"
                type="text"
                id="email"
                name="email"
                placeholder="User Role"
                disabled
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center btnLogout mb-5 "  onClick={logout} >
         
          <Logout className="me-2" onClick={logout} /> Logout
        </div>
      </div>
    </div>
  );
};

export default Profile;

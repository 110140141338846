import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddTrueAndFalseModal from "../AddTrueAndFalseModal/AddTrueAndFalseModal";
import ArabicModal from "../../TrueAndFalse/ArabicModal/ArabicModal";
import EnglishModal from "../../TrueAndFalse/EnglishModal/EnglishModal";
import ViewModal from "../../TrueAndFalse/ViewModal/ViewModal";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import LinearProgress from "@mui/material/LinearProgress";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function TrueAndFalseTable() {
  let authToken = localStorage.getItem("userToken");
  const [trueAndFalseQuestions, setTrueAndFalseQuestions] = useState([]);
  const [trueAndFalseQ, setTrueandFalseQ] = useState([]);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [tandfId, setId] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setloading] = useState(true); 
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getTrueAndFalse = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/true-false-question/",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    ).finally(() => {
      setloading(false);
  });
    setTrueAndFalseQuestions(data.data);
    setAr(data.data[0].languages[0]);
    setEn(data.data[0].languages[1]);
  };
  useEffect(() => {
    getTrueAndFalse();
  }, []);
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/true-false-question/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrueandFalseQ(data.data[0]);
    setAr(data.data[0].languages[0]);
    setEn(data.data[0].languages[1]);
  };
  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/true-false-question/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAr(data.data[0].languages[0]);
    setEn(data.data[0].languages[1]);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/true-false-question/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrueandFalseQ(data.data[0]);
    setEn(data.data[0].languages[1]);
    setAr(data.data[0].languages[0]);
  };
  const deleteRow = (id) => {
    const temp = [...trueAndFalseQuestions];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/true-false-question/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrueAndFalseQuestions(temp);
    getTrueAndFalse();
    setDeletePopUp(!deletePopUp);
  };
  return (
    <div className="">
      <AddTrueAndFalseModal getTrueAndFalse={getTrueAndFalse} ar={ar} en={en} />
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        trueAndFalseQ={trueAndFalseQ}
        setTrueandFalseQ={setTrueandFalseQ}
        ar={ar}
        en={en}
      />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getTrueAndFalse={getTrueAndFalse}
        deleteRow={deleteRow}
        id={tandfId}
      />
      <UpdatePopUp
      ar={ar}
      en={en}
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        trueAndFalseQ={trueAndFalseQ}
        setTrueandFalseQ={setTrueandFalseQ}
        id={tandfId}
        getTrueAndFalse={getTrueAndFalse}
      />
       <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
        {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
<div className="deleteForeverDiv mb-3">
        
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
           <div
          className="describeDel"
          onClick={() => {
            handleDeletePermenant();
          }}
        >
          Trash
        </div>
        </div>
       
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Degree
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Level
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Answer
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Title
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trueAndFalseQuestions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        <img src={row.image} className="img" alt="not found" />
                      </TableCell>
                      <TableCell align="center">{row.degree}</TableCell>
                      <TableCell align="center">{row.level}</TableCell>
                      <TableCell align="center">{row.answer}</TableCell>
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              // handleView();
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={trueAndFalseQuestions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Joi from "joi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  ageDetail,
  setAge,
  id,
  ageGroupId,
  getAges,
}) {
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [ageGroups,setAgeGroups]=useState([]);

  const [requestData, setRequestData] = useState({
    age: null,
    age_group_id: null,
  });
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setUpdatePopUp(false);
  };

  const validateAge = (city) => {
    let schema = Joi.object({
      age: Joi.number().required().messages({
        "number.empty": `"age" can't be an empty field`,
        "any.required": `"age" is a required field`,
      }),
      age_group_id: Joi.number().required().messages({
        "number.empty": `"age_group_id" can't be an empty field`,
        "any.required": `"age_group_id" is a required field`,
      }),
    });
    return schema.validate(city, { abortEarly: false });
  };
  let authToken = localStorage.getItem("userToken");

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("age", requestData.age);
    formData.append("age_group_id", requestData.age_group_id);
    let valRes = validateAge(requestData);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/age/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getAges();
          setRequestData("");
        }
      } catch (error) {
        getAges();
        setApiErrors(error.response.data.errors);
        setloading(false);
      }
    }
  };
   //get ageGroup Id
const getAgeGroups = async () => {
  let { data } = await axios.get(
    "https://atfalunaapp.com/api/dashboard/age-group",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setAgeGroups(data.data);
};
let ageGroupsIds = ageGroups.map((ageGroup) => ({
  id: ageGroup.id,
  name: ageGroup.name,
}));
useEffect(() => {
  getAgeGroups();
}, []);
//end of countries Id
  useEffect(() => {
    let{age}=ageDetail;
    setRequestData({age})
  }, [ageDetail]);
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} : {apiErrors[key][0]}
              </div>
            ))}
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Age
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="age"
              value={requestData.age}
              onChange={handleChange}
            />
          </div>
         
          <div className="mb-2">
              <label htmlFor="arVideo" className="form-label">
              Age Group Id
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
          
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.age_group_id}
                  onChange={handleChange}
                  name="age_group_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
             {ageGroupsIds.map((ageGroup) => (
                    <MenuItem key={ageGroup.id} value={ageGroup.id}>
                      {ageGroup.id} {`[ ${ageGroup.name} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          
          </div>

          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import ArabicModal from "../ArabicModal/ArabicModal";
import EnglishModal from "../EnglishModal/EnglishModal";
import SkinsIds from "../SkinsIds/SkinsIds";
import ActivityIds from "../ActivityIds/ActivityIds";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import AddAccessoryModal from "../AddAccessoryModal/AddAccessoryModal";
import LessonIds from "../LessonIds/LessonIds";
import ViewModal from "../ViewModal/ViewModal";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CachedIcon from "@mui/icons-material/Cached";
import FilterListIcon from "@mui/icons-material/FilterList";
import BodySuit from "../BodySuit/BodySuit";
export default function Hero() {
  let authToken = localStorage.getItem("userToken");
  const [accessories, setAccessories] = useState([]);
  const [accessory, setAccessory] = useState([]);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [skins, setSkins] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [activites, setActivites] = useState([]);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [id, setId] = useState();
  const [bodySuit, setBodySuit] = useState([]);
  const [bodySuitPopUp, setBodySuitPopUp] = useState(false);
  const [skinsPopUp, setSkinsPopUp] = useState(false);
  const [activityPopUp, setActivityPopUp] = useState(false);
  const [lessonPopUp, setLessonPopUp] = useState(false);
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    gender: "",
  });
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const handleSkins = () => {
    setSkinsPopUp(!skinsPopUp);
  };
  const handleBodySuit = () => {
    setBodySuitPopUp(!bodySuitPopUp);
  };
  const handeActivity = () => {
    setActivityPopUp(!activityPopUp);
  };
  const handleLesson = () => {
    setLessonPopUp(!lessonPopUp);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleFilter = () => {
    setFilter(!filter);
  };
  const handleChangeFilters = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  const showFiltered = async () => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/accessory?gender=${filterData.gender}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAccessories(data.data);
  };

  const clearFilter = () => {
    setFilterData({ gender: "" });
    getAccessories();
  };

  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/accessory/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAccessory(data.data[0]);
    setAr(data.data[0].languages[0]);
    setEn(data.data[0].languages[1]);
  };
  const getAccessories = async () => {
    let { data } = await axios
      .get("https://atfalunaapp.com/api/dashboard/accessory", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setAccessories(data.data);
  };

  useEffect(() => {
    getAccessories();
  }, []);
  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/accessory/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    console.log(data.data[0].languages);

    setSkins(await data.data[0].accessory_skin);
    setActivites(await data.data[0].accessory_activity);
    setLessons(await data.data[0].accessory_lesson);
    setBodySuit(await data.data[0].BodySuit);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/accessory/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAccessory(data.data[0]);
  };
  const deleteRow = (id) => {
    const temp = [...accessories];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/accessory/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAccessories(temp);
    getAccessories();
    setDeletePopUp(!deletePopUp);
  };

  return (
    <div className="">
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        accessory={accessory}
        setAccessory={setAccessory}
      />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getAccessories={getAccessories}
        deleteRow={deleteRow}
        id={id}
      />
      <AddAccessoryModal getAccessories={getAccessories} />
      <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        accessory={accessory}
        setAccessory={setAccessory}
        id={id}
        getAccessories={getAccessories}
        ar={ar}
        en={en}
      />
      <BodySuit
        setBodySuitPopUp={setBodySuitPopUp}
        bodySuitPopUp={bodySuitPopUp}
        bodySuit={bodySuit}
      />
      <SkinsIds
        skinsPopUp={skinsPopUp}
        setSkinsPopUp={setSkinsPopUp}
        skins={skins}
      />
      <ActivityIds
        activityPopUp={activityPopUp}
        setActivityPopUp={setActivityPopUp}
        activites={activites}
      />
      <LessonIds
        lessonPopUp={lessonPopUp}
        setLessonPopUp={setLessonPopUp}
        lessons={lessons}
      />
      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <div className="deleteForeverDiv mb-3">
        <div className="filerDiv d-flex description">
          <FilterListIcon className={"filterIcon"} onClick={handleFilter} />

          <div
            className="describeFilter"
            onClick={() => {
              handleFilter();
            }}
          >
            Filter
          </div>
        </div>
        <div className=" ms-2 me-2 divider align-self-center"></div>
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
          <div
            className="describeDel"
            onClick={() => {
              handleDeletePermenant();
            }}
          >
            Trash
          </div>
        </div>
      </div>
      <div className={`collapse mb-3   ${filter ? "d-flex" : "collapse"}`}>
        <div className="w-25 me-2">
          <label htmlFor="avatar" className="form-label">
            Gender
          </label>
          <div className="input-group mb-2">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="gender"
              value={filterData.gender}
              onChange={handleChangeFilters}
            >
              <option defaultValue>Choose...</option>
              <option value="boy">Boy</option>
              <option value="girl">Girl</option>
            </select>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-4 ">
          <FilterListIcon className={"filterIcon"} onClick={showFiltered} />
          <CachedIcon onClick={clearFilter} className={"reload"} />
        </div>
      </div>

      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: "auto" }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Price
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Body Suit
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Skins
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Activities
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Lessons
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accessories
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        <img src={row.image} alt="" className="img" />
                      </TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleBodySuit();
                              viewLang(row.id);
                            }}
                          >
                            Body Suit
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleSkins();
                              viewLang(row.id);
                            }}
                          >
                            Skins
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handeActivity();
                              viewLang(row.id);
                            }}
                          >
                            Activities
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleLesson();
                              viewLang(row.id);
                            }}
                          >
                            Lessons
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={accessories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Joi from "joi";
const initialRequestId = {
  image: null,
  url: "",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  updatePopUp,
  setUpdatePopUp,
  store,
  id,
  getStores,
  en,
  ar,
}) {
  let authToken = localStorage.getItem("userToken");
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [languageAr, setLanguagesAr] = useState({
    name: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    name: null,
  });

  const [requestData, setRequestData] = useState({
    ...initialRequestId,
  });
  useEffect(() => {
    let { name } = ar;
    setLanguagesAr({ name });
  }, [ar]);
  useEffect(() => {
    let { name } = en;
    setLanguagesEn({ name });
  }, [en]);
  useEffect(() => {
    let {url}=store;
    setRequestData({url})
  }, [store]);

  const handleClose = () => {
    setUpdatePopUp(false);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
    setLoading(false);
  };

  const handleImage = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };

  const handleChange = (e) => {
    setRequestData({
      ...requestData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };

  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
    });

    return schema.validate(en, { abortEarly: false });
  };


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();
    requestData.image&&
    formData.append("image", requestData.image);
    requestData.url&&
    formData.append("url", requestData.url);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][language]", "ar");
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][language]", "en");

    if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);
      setEnErrors(valEn.error.details);
    } else {
      try {
        setLoading(true);
        let { data } = await axios.post(
          `https://atfalunaapp.com/api/dashboard/store/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setLoading(false);
          getStores();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setLoading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
  if (!store || !ar || !en) return;
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>

        

          <div className="d-flex">
            <div className="me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div>
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="d-flex">
            <div className="mb-2 w-50 me-3 ">
              <label htmlFor="arTitle" className="form-label">
                Image
               
              </label>
              <input
                className="form-control"
                type="file"
                id="arTitle"
                name="image"
                onChange={handleImage}
              />
            </div>
            <div className="w-50">
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Url
                 
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="url"
                  value={requestData.url}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex mt-3">
            {/* -----------------------------------------arabic-------------------------------------------- */}

            <div className="w-50 me-3 ">
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  placeholder={ar.name}
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>
            </div>

            {/* -----------------------------------------english-------------------------------------------- */}
            <div className="w-50">
              <p className="mb-2  mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  placeholder={en.name}
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

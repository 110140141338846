import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import AddCertificationsModal from "../AddCertificationsModal/AddCertificationsModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import LinearProgress from "@mui/material/LinearProgress";
import ArabicModal from "../ArabicModal/ArabicModal";
import EnglishModal from "../EnglishModal/EnglishModal";
import ViewModal from "../ViewModal/ViewModal";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AgeGroup from '../AgeGroup/AgeGroup'
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CachedIcon from "@mui/icons-material/Cached";
import FilterListIcon from "@mui/icons-material/FilterList";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function Mcq() {
  let authToken = localStorage.getItem("userToken");
  const [certifications, setCertifications] = useState([]);
  const [certification, setCertification] = useState([]);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [ageGroupPopUp, setAgeGroupPopUp] = useState(false);
  const [ageGroup, setAgeGroup] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [subUsers, setSubUsers] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    sub_user_id: null,
    age_group_id: null,
    type: null,
  });
  const clearFilter = () => {
    setFilterData({ sub_user_id: "", age_group_id: "", type: "" });
    getCertifications();
  };
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const [cerId, setId] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleAgeGroup = () => {
    setAgeGroupPopUp(!ageGroupPopUp);
  };
  const handleFilter = () => {
    setFilter(!filter);
  };
  const handleChangeFilters = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };
  const showFiltered = async () => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/certificate/?sub_user_id=${
        filterData.sub_user_id == null ? "" : filterData.sub_user_id
      }&age_group_id=${
        filterData.age_group_id == null ? "" : filterData.age_group_id
      }
      &type=${filterData.type == null ? "" : filterData.type}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCertifications(data.data);
  };
  //get sub user Id
  const getSubUsers = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/sub-user",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubUsers(data.data);
  };
  let subUsersIds = subUsers.map((subUser) => ({
    id: subUser.id,
    name: subUser.name,
  }));
  useEffect(() => {
    getSubUsers();
  }, []);
  //get age group Id
  const getAgeGroups = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/age-group",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAgeGroups(data.data);
  };
  let ageGroupIds = ageGroups.map((ageGroup) => ({
    id: ageGroup.id,
    name: ageGroup.name,
  }));
  useEffect(() => {
    getAgeGroups();
  }, []);
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/certificate/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCertification(data.data[0]);
    setAr(data.data[0].languages[0]);
    setEn(data.data[0].languages[1]);
  };
  const getCertifications = async () => {
    let { data } = await axios
      .get("https://atfalunaapp.com/api/dashboard/certificate", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setCertifications(data.data);
  };

  useEffect(() => {
    getCertifications();
  }, []);

  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/certificate/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAgeGroup(await data.data[0].certificatable);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/certificate/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCertification(data.data[0]);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };

  return (
    <div className="">
      <AddCertificationsModal getCertifications={getCertifications} />
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        certification={certification}
        setCertification={setCertification}
        ar={ar}
        en={en}
      />

      <UpdatePopUp
        ar={ar}
        en={en}
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        certification={certification}
        setCertification={setCertification}
        id={cerId}
        getCertifications={getCertifications}
      />
      <AgeGroup
        setAgeGroupPopUp={setAgeGroupPopUp}
        ageGroupPopUp={ageGroupPopUp}
        ageGroup={ageGroup}
      />
      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <div className="deleteForeverDiv mb-3">
        <div className="filerDiv d-flex description">
          <FilterListIcon className={"filterIcon"} onClick={handleFilter} />

          <div
            className="describeFilter"
            onClick={() => {
              handleFilter();
            }}
          >
            Filter
          </div>
        </div>
      </div>
      <div className={`collapse mb-3  ${filter ? "d-flex" : "collapse"}`}>
        <div className="mb-2 w-25 me-2">
          <label htmlFor="arHeader" className="form-label">
            Sub User
          </label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="sub_user_id"
              value={filterData.sub_user_id}
              onChange={handleChangeFilters}
              MenuProps={MenuProps}
              InputLabelProps={{ shrink: false }}
              input={<OutlinedInput label=" " />}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              className="customSelect form-control"
            >
              {subUsersIds.map((subUser) => (
                <MenuItem key={subUser.id} value={subUser.id}>
                  {subUser.id} {`[${subUser.name ? subUser.name : "No Title"}]`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="mb-2 w-25 me-2">
          <label htmlFor="arHeader" className="form-label">
            Age Group
          </label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="age_group_id"
              value={filterData.age_group_id}
              onChange={handleChangeFilters}
              MenuProps={MenuProps}
              InputLabelProps={{ shrink: false }}
              input={<OutlinedInput label=" " />}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              className="customSelect form-control"
            >
              {ageGroupIds.map((ageGroup) => (
                <MenuItem key={ageGroup.id} value={ageGroup.id}>
                  {ageGroup.id}{" "}
                  {`[${ageGroup.name ? ageGroup.name : "No Title"}]`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 me-2">
          <label htmlFor="avatar" className="form-label">
            Type
          </label>
          <div className="input-group mb-2">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="type"
              value={filterData.type}
              onChange={handleChangeFilters}
            >
              <option defaultValue>Choose...</option>
              <option value="age_group">Age Group</option>
              <option value="subject">Subject</option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 ">
          <FilterListIcon className={"filterIcon"} onClick={showFiltered} />
          <CachedIcon onClick={clearFilter} className={"reload"} />
        </div>
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: "auto" }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image 1
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image 2
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image 3
                </TableCell>
           
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Max Points
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Min Points
                </TableCell>

    
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Age Group
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certifications
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={row.image_one}
                          className="img"
                          alt="not found"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={row.image_two}
                          className="img"
                          alt="not found"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <img
                          src={row.image_three}
                          className="img"
                          alt="not found"
                        />
                      </TableCell>

                      <TableCell align="center">{row.max_point}</TableCell>
                      <TableCell align="center">{row.min_point}</TableCell>
                     
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAgeGroup();
                              viewLang(row.id);
                            }}
                          >
                            Age Group
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={certifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@mui/material/Paper";
import LessonsIds from "../LessonsIds/LessonsIds";
import ArabicModal from "../ArabicModal/ArabicModal";
import EnglishModal from "../EnglishModal/EnglishModal";
import AddSubSubjectModal from "../AddSubSubjectModal/AddSubSubjectModal";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import ViewModal from "../ViewModal/ViewModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Quizs from "../Quizs/Quizs";
import Subjects from '../Subjects/Subjects';
import Skills from '../Skills/Skills';
const SubSubjectTable = () => {
  let authToken = localStorage.getItem("userToken");
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [subSubjects, setSubSubjects] = useState([]);
  const [subSubject, setSubSubject] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [lang, setLang] = useState();
  const [skills,setSkills]=useState([]);
  const [subject,setSubject]=useState([]);
  const [quiz, setQuiz] = useState([]);
  const [loading, setloading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [skillsPopUp, setSkillsPopUp] = useState(false);
  const [subjectPopUp, setSubjectPopUp] = useState(false);
  const [quizPopUp, setQuizPopUp] = useState(false);
  const [lessonsPopUp, setLessonsPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [quizId, setQuizId] = useState();
  const [subjectId, setSubjectId] = useState();
  const [id, setId] = useState();
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const handleLessons = () => {
    setLessonsPopUp(!lessonsPopUp);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleQuiz = () => {
    setQuizPopUp(!quizPopUp);
  };
  const handleSubject = () => {
    setSubjectPopUp(!subjectPopUp);
  };
  const handleSkills = () => {
    setSkillsPopUp(!skillsPopUp);
  };
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubSubject(data.data[0]);
    setQuizId(data.data[0].quiz ? data.data[0].quiz.id : "");
    setSubjectId(data.data[0].subject.id);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const viewLessons = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setLessons(await data.data[0].lessons);
  };
  const viewQuizs = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuiz(await data.data[0].quiz);
  };
  const viewSubject = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubject(await data.data[0].subject);
  };
  const viewSkills = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSkills(await data.data[0].skills);
  };
  const getSubSubject = async () => {
    let { data } = await axios
      .get(`https://atfalunaapp.com/api/dashboard/sub-subject`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setSubSubjects(data.data);
  };
  useEffect(() => {
    getSubSubject();
  }, []);
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubSubject(data.data[0]);
  };
  
  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const deleteRow = (id) => {
    const temp = [...subSubjects];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/sub-subject/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubSubjects(temp);
    getSubSubject();
    setDeletePopUp(!deletePopUp);
  };
  return (
    <div>
      <AddSubSubjectModal getSubSubject={getSubSubject} />
      <LessonsIds
        setLessonsPopUp={setLessonsPopUp}
        lessonsPopUp={lessonsPopUp}
        lessons={lessons}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        subSubject={subSubject}
        setSubSubject={setSubSubject}
      />
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getSubSubject={getSubSubject}
        deleteRow={deleteRow}
        id={id}
      />
      <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        subSubject={subSubject}
        setSubSubject={setSubSubject}
        id={id}
        getSubSubject={getSubSubject}
        ar={ar}
        en={en}
        quizId={quizId}
        subjectId={subjectId}
      />

      <Quizs quizPopUp={quizPopUp} setQuizPopUp={setQuizPopUp} quiz={quiz} />
      <Subjects subjectPopUp={subjectPopUp} setSubjectPopUp={setSubjectPopUp} subject={subject} />
      <Skills skillsPopUp={skillsPopUp} setSkillsPopUp={setSkillsPopUp} skills={skills} />

      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <div className="deleteForeverDiv mb-3">
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
          <div
            className="describeDel"
            onClick={() => {
              handleDeletePermenant();
            }}
          >
            Trash
          </div>
        </div>
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>

                {/* <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Points
                </TableCell> */}
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Subject
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Quiz
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Skills
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Lessons
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subSubjects
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      {/* <TableCell align="center">{row.points}</TableCell> */}
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleSubject();
                              viewSubject(row.id);
                            }}
                          >
                            Subject
                          </button>
                        </div>
                      </TableCell>{" "}
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleQuiz();
                              viewQuizs(row.id);
                            }}
                          >
                            Quiz
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleSkills();
                              viewSkills(row.id);
                            }}
                          >
                            Skills
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleLessons();
                              viewLessons(row.id);
                            }}
                          >
                            Lessons
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={subSubjects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default SubSubjectTable;

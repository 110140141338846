import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import axios from "axios";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import LinearProgress from "@mui/material/LinearProgress";
import ArabicModal from "../ArabicModal/ArabicModal";
import EnglishModal from "../EnglishModal/EnglishModal";
import ViewModal from "../ViewModal/ViewModal";
import MatchQuetsion from '../MatchQuestion/MatchQuestion'
import AddMatchAnswerModal from "../AddMatchAnswerModal/AddMatchAnswerModal";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
export default function Mcq() {
  let authToken = localStorage.getItem("userToken");
  const [matchAnswers, setMatchAnswers] = useState([]);
  const [matchAnswer, setMatchAnswer] = useState([]);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [matchId, setId] = useState();
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const [matchQuestion, setMatchQuestion] = useState([]);
  const [matchQPopUp, setMatchQPopUp] = useState(false);
  const [match_answer,setMatch_answer]=useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleQuestionPopUp = () => {
    setMatchQPopUp(!matchQPopUp);
  };
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/match-answer/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
    setMatchQuestion(data.data[0].match_question);
    setMatch_answer(data.data[0].match_answer);
  };

  const getMatchAnswers = async () => {
    let { data } = await axios
      .get("https://atfalunaapp.com/api/dashboard/match-answer", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setMatchAnswers(data.data);
  };

  useEffect(() => {
    getMatchAnswers();
  }, []);
  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/match-answer/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
    setMatchQuestion(data.data[0].match_question);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/match-answer/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMatchAnswer(data.data[0]);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const deleteRow = (id) => {
    const temp = [...matchAnswers];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/match-answer/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMatchAnswers(temp);
    getMatchAnswers();
    setDeletePopUp(!deletePopUp);
  };
  return (
    <div className="">
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        matchAnswer={matchAnswer}
        setMatchAnswer={setMatchAnswer}
        ar={ar}
        en={en}
      />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getMatchAnswers={getMatchAnswers}
        deleteRow={deleteRow}
        id={matchId}
      />
      <AddMatchAnswerModal getMatchAnswers={getMatchAnswers} />
      <UpdatePopUp
        ar={ar}
        en={en}
        matchQuestion={matchQuestion}
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        setMatchAnswer={setMatchAnswer}
        matchAnswer={matchAnswer}
        id={matchId}
        getMatchAnswers={getMatchAnswers}
        match_answer={match_answer}
      />
         <MatchQuetsion
        setMatchQPopUp={setMatchQPopUp}
        matchQPopUp={matchQPopUp}
        matchQuestion={matchQuestion}
      />
      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      
      <div className="deleteForeverDiv mb-3">
        
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
           <div
          className="describeDel"
          onClick={() => {
            handleDeletePermenant();
          }}
        >
          Trash
        </div>
        </div>
       
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Title
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Position
                </TableCell>
           
              
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Match Answer Id 
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Match Q 
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchAnswers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id&&row.id}
                      </TableCell>
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">
                        <img src={row.image} className="mAnsImg" alt="not found" />
                      </TableCell>
                 
                      <TableCell align="center">{row.possition}</TableCell>

            
                      <TableCell align="center">{row.match_answer_id?row.match_answer_id:"Not Found"}</TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleQuestionPopUp();
                              viewLang(row.id);
                            }}
                          >
                           Match Q
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={matchAnswers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

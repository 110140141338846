import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import RestoreIcon from "@mui/icons-material/Restore";
import PermanentDeleteHeader from "../PermanentDeleteHeader/PermanentDeleteHeader";
import PerrmanentDeletePopUp from "../PermanentDelete/PerrmanentDeletePopUp";
import PermanentViewPopUp from "../PermanentView/PermanentViewPopUp";
import PermanentRestore from "../PermanentRestore/PermanentRestore";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "RGB(242, 239, 237)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeletePopUp({
  deletePermenant,
  setDeletePermenantPopUp,
}) {
  let authToken = localStorage.getItem("userToken");
  const title = "Trash";
  const [trashs, setTrashs] = useState([]);
  const [trash, setTrash] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [restorePopUp, setRestorePopUp] = useState(false);
  const [id, setId] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setDeletePermenantPopUp(false);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleRestore = () => {
    setRestorePopUp(!restorePopUp);
  };
  const getTrashCollection = async () => {
    let { data } = await axios.get(
      "https://atfalunaapp.com/api/dashboard/activity/collection-trash?PerPage=1000&page=1",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrashs(data.data);
  };
  useEffect(() => {
    getTrashCollection();
  }, []);
  useEffect(() => {
    getTrashCollection();
  }, [trash]);
  const premanentlyDelete = (id) => {
    const temp = [...trashs];
    axios.delete(
      `https://atfalunaapp.com/api/dashboard/activity/premanently-delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrashs(temp);
    getTrashCollection();
    setDeletePopUp(!deletePopUp);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/activity/${id}/show-trash`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrash(data.data[0]);
  };
  const viewBeforeRestore = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/activity/${id}/show-trash`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTrash(data.data[0]);
  };
  return (
    <div>
      <Modal
        open={deletePermenant}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <PermanentDeleteHeader title={title} />
          <PerrmanentDeletePopUp
            deletePopUp={deletePopUp}
            setDeletePopUp={setDeletePopUp}
            getTrashCollection={getTrashCollection}
            premanentlyDelete={premanentlyDelete}
            id={id}
          />
          <PermanentViewPopUp
            viewPopUp={viewPopUp}
            setViewPopUp={setViewPopUp}
            trash={trash}
            setTrash={setTrash}
          />
          <PermanentRestore
            restorePopUp={restorePopUp}
            setRestorePopUp={setRestorePopUp}
            trash={trash}
            setTrash={setTrash}
            id={id}
            getTrashCollection={getTrashCollection}
          />
          <Paper
            sx={{
              overflow: "hidden",
              margin: "auto",
              textAlign: "center",
              overflowX: "auto",
              width: "100%",
              mb: 3,
            }}
          >
            <TableContainer
              sx={{ maxHeight: 700, minWidth: 800 }}
              className="tableDesign"
            >
              <Table
                stickyHeader
                aria-label="sticky table "
                sx={{ width: 1, margin: "auto", overflowX: "auto" }}
              >
              
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                    <TableCell
                      style={{
                        fontWeight: "700",
                        color: "rgba(255, 73, 73, 1)",
                      }}
                    >
                      ID
                    </TableCell>
              
                    <TableCell
                      style={{
                        fontWeight: "700",
                        color: "rgba(255, 73, 73, 1)",
                      }}
                      align="center"
                    >
                      Name
                    </TableCell>
                   
                    <TableCell
                      style={{
                        fontWeight: "700",
                        color: "rgba(255, 73, 73, 1)",
                      }}
                      align="center"
                    >
                      Points
                    </TableCell>
                   
                    <TableCell
                      style={{
                        fontWeight: "700",
                        color: "rgba(255, 73, 73, 1)",
                      }}
                      align="center"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trashs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            color: "red",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <div className="smallBg">{index + 1}</div>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                        
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.points}</TableCell>


                          <TableCell align="center">
                            <DeleteForeverIcon
                              className="delIcon me-1"
                              onClick={() => {
                                setId(row.id);
                                handleDeleteAlert();
                              }}
                            />
                            <VisibilityIcon
                              className="viewIcon me-1"
                              onClick={() => {
                                handleView();
                                viewRow(row.id);
                              }}
                            />
                            <RestoreIcon
                              className="editIcon "
                              onClick={() => {
                                setId(row.id);
                                viewBeforeRestore(row.id);
                                handleRestore();
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{ textAlign: "center" }}
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={trashs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

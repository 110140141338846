import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import axios from "axios";
import UpdateModal from "../AboutUsUpdateModal/UpdateModal";
import LinearProgress from "@mui/material/LinearProgress";
export default function AboutUs() {
  let authToken = localStorage.getItem("userToken");
  const [aboutUs, setAboutUs] = React.useState({});
  const [id, setId] = React.useState();
  const [loading, setloading] = useState(true);
  const getAboutUs = async (id) => {
    let { data } = await axios
      .get(
        `https://atfalunaapp.com/api/dashboard/about_us/${id}/show`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      )
      .finally(() => {
        setloading(false);
      });
    setAboutUs(data.data[0]);
    setId(data.data[0].id);
  };
  useEffect(() => {
    getAboutUs(2);
  }, []);

  return (
    <Box>
      <UpdateModal
        aboutUs={aboutUs}
        setAboutUs={setAboutUs}
        id={id}
        getAboutUs={getAboutUs}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          overflowX: "hidden",
          width: "100%",
          mb: 3,
        }}
      >
        <div>
          <div className="d-flex justify-content-center mt-3">
            <button
              className="btnDesign me-1"
              onClick={() => {
                getAboutUs(1);
              }}
            >
              EN
            </button>
            <div className="divider align-self-center"></div>
            <button
              className="btnDesign ms-1"
              onClick={() => {
                getAboutUs(2);
              }}
            >
              AR
            </button>
          </div>
          <div className="row">
            <div className="col-lg-3 mt-2 mb-2">
              <div className="col-lg-12">
                <div className="leftImg ms-2 mb-5">
                  <img src={aboutUs.image_three} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="leftImg ms-2">
                  <img src={aboutUs.image_four} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-lg-12 mt-5 aboutUsTitle fw-bold mb-5">
                <h4>{aboutUs.title}</h4>
              </div>

              <div className=" mb-5 ms-3 me-3 aboutUsText col-lg-12 text-muted">
                {aboutUs.subject}
              </div>
            </div>
            <div className="col-lg-3 mt-2 mb-2">
              <div className="col-lg-12">
                <div className="rightImg ms-2 mb-5">
                  <img src={aboutUs.image_one} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="rightImg ms-2 ">
                  <img src={aboutUs.image_two} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
}

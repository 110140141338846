import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import Paper from "@mui/material/Paper";
import PlayTime from "../PlayTime/PlayTime";
import AddPlayTimeModal from "../AddPlayTimeModal/AddPlayTimeModal";
import UpdatePopUp from "../UpdateModal/UpdateModal";

const PlayTimeTable = () => {
  let authToken = localStorage.getItem("userToken");

  const [playTimes, setPlayTimes] = useState([]);
  const [playTime, setPlayTime] = useState([]);
  const [loading, setloading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [playTimePopUp, setPlayTimePopUp] = useState(false);

    const [updatePopUp, setUpdatePopUp] = useState(false);
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handlePlayTime = () => {
    setPlayTimePopUp(!playTimePopUp);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


      const handleUpdate = () => {
        setUpdatePopUp(!updatePopUp);
      };


  const getPlayTimes = async () => {
    let { data } = await axios
      .get(`https://atfalunaapp.com/api/dashboard/sub-user`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setPlayTimes(data.data);
  };
  useEffect(() => {
    getPlayTimes();
  }, []);
  const viewPlayTime = async (id) => {
    let { data } = await axios.get(
      `https://atfalunaapp.com/api/dashboard/sub-user/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setPlayTime(await data.data[0].play_time);
  };

  return (
    <div>
      <AddPlayTimeModal getPlayTimes={getPlayTimes} />
      <PlayTime
        playTimePopUp={playTimePopUp}
        setPlayTimePopUp={setPlayTimePopUp}
        playTime={playTime}
      />
        <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        getPlayTimes={getPlayTimes}
      />

      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      {/* */}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  Sub-User ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  Play Time
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {playTimes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell align="">
                        <button
                          className="btnDesign me-1"
                          onClick={() => {
                            handlePlayTime();
                            viewPlayTime(row.id);
                          }}
                        >
                          Play Time 
                        </button>
                      </TableCell>                 
                      <TableCell align="center">
                   
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={playTimes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default PlayTimeTable;
